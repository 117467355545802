<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px">
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="dataForm.name" placeholder="项目名称"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="updatePasswd" v-if="!dataForm.id">
        <el-input v-model="dataForm.updatePasswd" placeholder="密码" type="password"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="dataForm.remark"
                  placeholder="备注" maxlength="1000" show-word-limit/>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getByIdProject , saveOrUpdateProject } from '@/api/hbi/project.js'
import { encryptDes } from "@/utils/des";
import { validatorPassword } from '@/utils/validate'
export default {
  data () {
    var validatePassword = (rule, value, callback) => {
      if (!this.dataForm.id && !validatorPassword(value)) {
        callback(new Error('密码要求长度8~20位,并且不能为纯数字或纯字母!'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      isOnSubmit: false,
      formLoading: false,
      dataForm: {
        id: null,
        name: '',
        updatePasswd: '',
        remark: '',
      },
      dataRule: {
        name: [
          { required: true, message: '项目名称不能为空', trigger: 'blur' }
        ],
        updatePasswd: [
          { validator: validatePassword, trigger: "blur" }
        ],
        remark: [
          { required: true, message: '备注不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init (id) {
      alert("33")
      this.dataForm.id = id
      this.visible = true
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.formLoading = true
          getByIdProject(id).then(({data}) => {
            if (data && data.code === 0) {
              let res = data.data
              for(let item in this.dataForm){
                if(res[item] || res[item] === 0){
                  this.dataForm[item] = res[item] + ''
                }else{
                  this.dataForm[item] = ''
                }
              }
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.formLoading = false
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          // 密码加密
          const n_pass = this.dataForm.updatePasswd
              ? encryptDes(this.dataForm.updatePasswd)
              : null;
          const data_ = {
            'id': this.dataForm.id || undefined,
            'name': this.dataForm.name,
            'updatePasswd': n_pass,
            'remark': this.dataForm.remark
          }
          saveOrUpdateProject(data_).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(()=>{
            this.isOnSubmit = false
          })
        }
      })
    }
  }
}
</script>
