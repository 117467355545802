import http from '@/utils/httpRequest'

// 列表获取
export function getList(projectId) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/report/list/${projectId}`),
        method: 'get'
    })
}

// 删除数据
export function deleteData(data = []) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/report/delete/${data.projectId}`),
        method: "post",
        data: http.adornData([data.reportId], false)
    })
}

// 根据ID 获取
export function getById(id) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectappreport/info/${id}`),
        method: "get"
    })
}

// 更新或新增
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/relation/report/save/${data.projectId}`
        ),
        method: "post",
        data: http.adornData([data.reportId], false)
    })
}

// App列表获取
export function getAppList() {
    return http({
        url: http.adornUrl('/hbi/hbiprojectmenu/appList'),
        method: 'get',
        params: http.adornParams()
    })
}