import http from '@/utils/httpRequest'

// 列表获取
export function getList(projectId) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/user/list/${projectId}`),
        method: "get"
    })
}

// 删除数据
export function deleteData(data) {
    return http({
        url: http.adornUrl(`/hbi/project/relation/user/delete/${data.projectId}`),
        method: "post",
        data: http.adornData([data.userIds], false)
    })
}

// 根据ID 获取
export function getById(id) {
    return http({
        url: http.adornUrl(`/hbi/hbiprojectuser/info/${id}`),
        method: "get"
    })
}

// 更新或新增
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/relation/user/save/${data.projectId}`
        ),
        method: "post",
        data: http.adornData([data.userIds], false)
    })
}