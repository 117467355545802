<template>
    <el-dialog
            :title="'修改密码'"
            :close-on-click-modal="false"
            :visible.sync="visible"
            width="40%">
        <el-form v-loading="formLoading" :model="dataForm" :rules="dataRule" ref="dataForm"
                 @keyup.enter.native="dataFormSubmit()" label-width="80px">
            <el-form-item label="原密码:" prop="updatePasswd">
                <el-input v-model="dataForm.updatePasswd" placeholder="密码" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码:" prop="newPassword">
                <el-input v-model="dataForm.newPassword" placeholder="新密码" type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码:" prop="confirmPassword">
                <el-input type="password" v-model="dataForm.confirmPassword" placeholder="确认密码"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" :loading="isOnSubmit">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :loading="formLoading || isOnSubmit">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    import {encryptDes} from "@/utils/des";
    import {validatorPassword} from '@/utils/validate'
    import {updatePassword} from '@/api/hbi/project/project.js';

    export default {
        data() {
            var validatePass = (rule, value, callback) => {
                if (!validatorPassword(value)) {
                    callback(new Error('密码要求长度8~20位,并且不能为纯数字或纯字母!'))
                } else {
                    callback()
                }
            }
            var validateConfirmPassword = (rule, value, callback) => {

                if (this.dataForm.newPassword != value) {
                    callback(new Error('确认密码与新密码不一致'))
                } else {
                    callback()
                }
            }
            return {
                visible: false,
                isOnSubmit: false,
                formLoading: false,
                dataForm: {
                    id: null,
                    updatePasswd: '',
                    newPassword: '',
                    confirmPassword: ''
                },
                dataRule: {
                    updatePasswd: [
                        { required: true, message: '原密码不能为空', trigger: 'blur' }
                    ],
                    newPassword: [
                        { required: true, message: '新密码不能为空', trigger: 'blur' },
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    confirmPassword: [
                        { required: true, message: '确认密码不能为空', trigger: 'blur' },
                        { validator: validateConfirmPassword, trigger: 'blur' },
                        { validator: validatePass, trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            init(id) {
                this.dataForm.id = id
                this.visible = true
                this.isOnSubmit = false
                this.dataForm.updatePasswd = null
                this.dataForm.newPassword = null
                this.dataForm.confirmPassword = null
            },
            // 表单提交
            dataFormSubmit() {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.isOnSubmit = true
                        //密码加密
                        const o_pass = encryptDes(this.dataForm.updatePasswd)
                        const n_pass = encryptDes(this.dataForm.newPassword)
                        const data_ = {
                            'id': this.dataForm.id || undefined,
                            'updatePasswd': o_pass,
                            'newPassword':n_pass
                        }
                        updatePassword(data_).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 500
                                })
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        }).catch((err) => {
                            this.$message.error(err)

                        }).finally(() => {
                            this.isOnSubmit = false
                        })
                    }
                })
            }
        }
    }
</script>
