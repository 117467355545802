import http from '@/utils/httpRequest'

// 列表获取
export function getList(params) {
    return http({
        url: http.adornUrl('/hbi/project/list'),
        method: 'get',
        params: params
    })
}

// 列表获取
export function getListAll(params) {
    return http({
        url: http.adornUrl('/hbi/project/listAll'),
        method: 'get',
        params: params
    })
}

// 删除数据
export function deleteData(ids = []) {
    return http({
        url: http.adornUrl("/hbi/project/delete"),
        method: "post",
        data: http.adornData(ids, false)
    })
}

// 根据ID 获取
export function getById(id) {
    return http({
        url: http.adornUrl(`/hbi/project/info/${id}`),
        method: "get"
    })
}

// 更新或新增
export function saveOrUpdate(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/${!data.id ? "save" : "update"}`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 项目修改操作前密码校验
export function validatePassword(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/validate`
        ),
        method: "post",
        data: http.adornData(data)
    })
}

// 修改密码
export function updatePassword(data) {
    return http({
        url: http.adornUrl(
            `/hbi/project/updatePassword`
        ),
        method: "post",
        data: http.adornData(data)
    })
}