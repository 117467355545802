<template>
    <div class="basic-container">
        <div class="mainPage" v-show="!deployMenuVisible && !deployUserVisible && !deployBscreenVisible && !deployAppVisible && !deployVisible">
            <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)"
                     @submit.native.prevent>
                <table class="detailTable_search" style="width: 100%">
                    <tr>
                        <td class="titleTd width80px">项目名称</td>
                        <td class="valueTd">
                            <el-input v-model="dataForm.name" placeholder="项目名称" clearable></el-input>
                        </td>
                    </tr>
                </table>
            </el-form>
            <div class="wxts_msg_search">
                <div class="f_t">
                    <el-button-group>
                        <el-button @click="currentChangeHandle(1)" :loading="dataListLoading">查询</el-button>
                        <el-button v-if="isAuth('hbi:hbiproject:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
                    </el-button-group>
                </div>
            </div>
            <el-table :data="dataList" border v-loading="dataListLoading"  @selection-change="selectionChangeHandle" style="width: 100%;">

                <el-table-column prop="name" header-align="center" align="center" label="项目名称">
                </el-table-column>
                <el-table-column prop="remark" header-align="center" align="center" label="备注">
                </el-table-column>
                <el-table-column  header-align="center" align="center" width="450" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   @click="deployHandle(scope.row)">配置
                        </el-button>
                        <el-button type="text" size="small"
                                   @click="getPassword('deployMenuHandle',scope.row)">配置菜单
                        </el-button>
                        <el-button type="text" size="small"
                                   @click="getPassword('deployUserHandle',scope.row)">配置人员
                        </el-button>
                        <el-button type="text" size="small"
                                   @click="getPassword('deployBscreenHandle',scope.row)">配置大屏
                        </el-button>
                        <el-button type="text" size="small"
                                   @click="getPassword('deployAppHandle',scope.row)">配置app报表
                        </el-button>
                        <el-button type="text" size="small" v-if="isAuth('hbi:hbiproject:update')"
                                   @click="addOrUpdateHandle(scope.row.id)">修改
                        </el-button>
                        <el-button type="text" size="small" v-if="isAuth('hbi:hbiproject:delete')"
                                   @click="deleteHandle(scope.row)">删除
                        </el-button>
                        <el-button type="text" size="small"
                                   @click="updatePasswordHandle(scope.row.id)">修改密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalCount"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <!-- 弹窗, 新增 / 修改 -->
            <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
        </div>
        <deployMenu v-if="deployMenuVisible" ref="deployMenu" @refreshDataList="backMenuPage"></deployMenu>
        <deployUser v-if="deployUserVisible" ref="deployUser" @refreshDataList="backUserPage"></deployUser>
        <deployBscreen v-if="deployBscreenVisible" ref="deployBscreen" @refreshDataList="backBscreenPage"></deployBscreen>
        <deployApp v-if="deployAppVisible" ref="deployApp" @refreshDataList="backAppPage"></deployApp>
        <updatePassword v-if="updatePasswordVisible" ref="updatePassword" @refreshDataList="getDataList"></updatePassword>
        <deploy v-if="deployVisible" ref="deploy" @refreshDataList="bakePAge"></deploy>


    </div>
</template>

<script>
    import AddOrUpdate from './add-or-update'
    import DeployMenu from './deployMenu'
    import DeployUser from './deployUser'
    import DeployBscreen from './deployBscreen'
    import DeployApp from './deployApp'
    import {getProjectList, deleteProjectData, validatePassword} from '@/api/hbi/project.js'
    import { encryptDes } from "@/utils/des";
    import UpdatePassword from './updatePassword'
    import Deploy from './deploy'

    export default {
        data() {
            return {
                dataForm: {
                    name: ''
                },
                dataList: [],
                pageIndex: 1,
                pageSize: 10,
                totalCount: 0,
                dataListLoading: false,
                dataListSelections: [],
                addOrUpdateVisible: false,
                deployMenuVisible: false,
                deployUserVisible: false,
                deployBscreenVisible: false,
                deployAppVisible: false,
                updatePasswordVisible: false,
                deployVisible: false
            }
        },
        components: {
            AddOrUpdate, DeployMenu, DeployUser, DeployBscreen, DeployApp, UpdatePassword,Deploy
        },
        created() {
            this.getDataList();
        },
        activated() {
        },
        computed: {
        },
        methods: {
            // 获取数据列表
            getDataList() {
                this.dataListLoading = true
                const params_ = {
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'name': this.dataForm.name
                }
                getProjectList(params_).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataList = data.data.list
                        this.totalCount = data.data.totalCount
                    } else {
                        this.dataList = []
                        this.totalCount = 0
                        this.$message.error(data.msg)
                    }
                }).catch((err) => {
                    console.error(err)
                }).finally(() => {
                    this.dataListLoading = false
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增 / 修改
            addOrUpdateHandle(id) {
                this.addOrUpdateVisible = true
                this.$nextTick(() => {
                    this.$refs.addOrUpdate.init(id)
                })
            },
            // 删除
            deleteHandle(row) {
                const ids = row ? [row.id] : this.dataListSelections.map(item => {
                    return item.id
                })

                this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataListLoading = true
                    deleteProjectData(ids).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000
                            })
                            this.getDataList()
                        } else {
                            this.$message.error(data.msg)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                        this.dataListLoading = false
                    })
                }).catch(() => {
                })
            },

            // 配置
            deployHandle(row) {
                this.deployVisible = true
                this.$nextTick(() => {
                    this.$refs.deploy.deploy(row)
                })
            },

            /**
             * 返回列表页
             * @param refresh 返回后是否刷新列表
             */
            bakePAge(refresh) {
                this.deployVisible = false
                if (refresh) {
                    this.getDataList()
                }
            },

            /**
             * 返回列表页
             * @param refresh 返回后是否刷新列表
             */
            backMenuPage(refresh) {
                this.deployMenuVisible = false
                if (refresh) {
                    this.getDataList()
                }
            },

            /**
             * 返回列表页
             * @param refresh 返回后是否刷新列表
             */
            backUserPage(refresh) {
                this.deployUserVisible = false
                if (refresh) {
                    this.getDataList()
                }
            },

            /**
             * 返回列表页
             * @param refresh 返回后是否刷新列表
             */
            backBscreenPage(refresh) {
                this.deployBscreenVisible = false
                if (refresh) {
                    this.getDataList()
                }
            },

            /**
             * 返回列表页
             * @param refresh 返回后是否刷新列表
             */
            backAppPage(refresh) {
                this.deployAppVisible = false
                if (refresh) {
                    this.getDataList()
                }
            },

            // 配置菜单
            deployMenuHandle(row,_this) {
                _this.deployMenuVisible = true
                _this.$nextTick(() => {
                    _this.$refs.deployMenu.deployMenu(row)
                })
            },

            // 配置人员
            deployUserHandle(row,_this) {
                _this.deployUserVisible = true
                _this.$nextTick(() => {
                    _this.$refs.deployUser.deployUser(row)
                })
            },

            // 配置大屏
            deployBscreenHandle(row,_this) {
                _this.deployBscreenVisible = true
                _this.$nextTick(() => {
                    _this.$refs.deployBscreen.deployBscreen(row)
                })
            },

            // 配置app报表
            deployAppHandle(row,_this) {
                _this.deployAppVisible = true
                _this.$nextTick(() => {
                    _this.$refs.deployApp.deployApp(row)
                })
            },

            //校验密码
            getPassword(funcName,row){
                this.$prompt("请输入项目 [ " + row.name + " ] 的密码", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    closeOnClickModal: false,
                    closeOnHashChange: false,
                }).then(({ value }) => {
                    const n_pass = encryptDes(value); // encrypt(value);
                    validatePassword({
                        id: row.id,
                        updatePasswd: n_pass
                    }).then(({ data }) => {
                        if (data && data.code === 0) {
                            let list = this.$options.methods
                            const _this = this
                            list[funcName](row,_this)
                        }
                    }).catch((error) =>{
                        //this.getPassword(funcName,row)
                    });
                }).catch(() => {});
            },

            // 修改密码
            updatePasswordHandle(id) {
                this.updatePasswordVisible = true
                this.$nextTick(() => {
                    this.$refs.updatePassword.init(id)
                })
            },
        }
    }
</script>
